<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {

        
            covFields: [
                { key: "service", sortable: true, label: "Service" },
                { key: "active", sortable: true, label: "Statut" },
                { key: "ouverture", sortable:true, label:"Date de reprise"},
                {key: "distance_minimum", sortable: true, label: "Distance min"},
                { key: "action", sortable: true, label: "Action", },


            ],
            currentItem: null,
            distance_minimum: null, // Variable pour stocker la valeur de `distance_minimum` sélectionnée
            editDistanceModal: false, // Modal pour modifier `distance_minimum`

            title: "GESTION DES TARIFS ET COMMISIONS",
            items: [
                {
                    text: "Dashboard",
                },
                {
                    text: "TARIFS ET COMMISIONS",
                    active: true,
                },
            ],

            
            totalRows: 1,
            changeCurrent: 1,
            changePerPage: 100,
            currentPage: 1,
            currentPageD: 1,
            perPage: 100,
            perPageD: 100,
            pageOptions: [100, 250, 500],
            pageOptionsD: [100, 250, 500],
            filter: null,
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            paginatedDate: [],
            carData: [],
            serviceData: [],
            tarifFormData: {
                id: null,
                moyenTransport: "",
                prixBase: null,
                prixSecond: null,
                montantAssurance: null,
                montantAttenteParMin: null,
            },
            editTarifModal: false,
            editCovTarifModal: false,
            service:"",
            dateOuverture:"",
            dateModal: false,


        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.dataUser.length;
        },
        rowsinactif() {
            return this.dataUserD.length;
        },
    },
    async mounted() {
        this.getService()

    },
    methods: {

        // Méthode pour ouvrir le modal de modification de `distance_minimum`
        handleDistanceUpdateClick(item) {
            console.log("service to distance", item)
            //this.distanceMinimum = item.distance_minimum;
            this.distance_minimum = item.distance_minimum;
            this.currentItem = item;
            this.service = item.service;
            this.editDistanceModal = true;
            console.log(this.distance_minimum)
        },

        handleUpdateClick(item){
            console.log("service to update", item)
            this.service = item.service
            this.dateModal = true
        },

        // async updateDistanceMinimum() {
        //     const response = await apiRequest('POST', 'services-rmo-update', {
        //             service: this.service,
        //             distance_minimum: this.distance_minimum
        //         });
        //     if (response && response.status == 200) {
        //         this.$toast.success("Distance minimum mise à jour avec succès");
        //         this.editDistanceModal = false;
        //         this.serviceData =[]
        //         this.getService(); // Recharger les données
        //     } else {
        //         this.$toast.error("Echec de la mise à jour de la distance minimum");
        //     }
        // },

        async updateDistanceMinimum() {
            try {
                console.log(this.distance_minimum);
                const response = await apiRequest('POST', 'services-rmo-update', {
                    service: this.service,
                    distance_minimum: this.distance_minimum
                });
                
                if (response && response.status === 200) {
                    // Mise à jour locale de la valeur
                    const index = this.serviceData.findIndex(item => item.service === this.service);
                    if (index !== -1) {
                        this.serviceData[index].distance_minimum = this.distance_minimum;
                    }
                    
                    this.$toast.success("Distance minimum mise à jour avec succès");
                    this.editDistanceModal = false;
                    
                    // Rafraîchir les données
                    this.serviceData = [];
                    await this.getService();
                } else {
                    this.$toast.error("Échec de la mise à jour de la distance minimum");
                }
            } catch (error) {
                console.error("Erreur lors de la mise à jour:", error);
                this.$toast.error("Une erreur est survenue lors de la mise à jour");
            }
        },
    
       async updateDate(){
        const req = await apiRequest('POST','services-rmo-update',{service:this.service,dateOuverture:this.dateOuverture})
        console.log(req)
        if(req.status == 200){
            this.service =""
            this.dateOuverture= ""
            this.dateModal = false;
            this.$toast.success("Opération réussie")
            this.serviceData =[]
            this.getService();
        }else{
            this.$toast.error("Un problème est survenu")

        }

       },

        async getService() {
            //  messageContent
            const response = await apiRequest('GET', 'find-services-dash', false)
            if (response && response.status == 200) {
                console.log("D ta up here",response .data)
                const sortedData = response.data.sort((a, b) => b.id - a.id);

                sortedData.map(el => {
                    this.serviceData.push(el);
                })
                console.log("this service ", this.serviceData);
            }

        },

    
        async enableService(item) {
            //  messageContent

            console.log("enable")

            const response = await apiRequest('POST', 'services-rmo', {
                service: item.service,
                active: item.active,

            })
            if (response && response.status == 200) {
                //console.log("D ta up here",response .data
this.serviceData=[]
                this.$toast.success("Modifications apportées avec succès !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.carData = []
                await this.getService();

            } else {
                
                this.editCovTarifModal = false
                this.$toast.error("Echec de la modification !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

            }

        },

        async disableService(item) {
            //  messageContent

            this.serviceData=[]


            const response = await apiRequest('POST', 'services-rmo', {
                service: item.service,
                active: item.active,

            })
            if (response && response.status == 200) {
                //console.log("D ta up here",response .data

                this.$toast.success("Modifications apportées avec succès !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.carData = []
                await this.getService();

            } else {
                this.editCovTarifModal = false
                this.$toast.error("Echec de la modification !!!", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

            }

        },
      
    handleServiceChange(item) {
    console.log("Service status changed:", item);
    if (item.active) {
        this.enableService(item);
    } else {
        this.disableService(item);
    }
}







    },

    watch: {
        activeConducteur(newValue, oldValue) {
            if (newValue === true) {
                this.userChargeLoader = true;
                console.log("Old", oldValue);

                if (this.newDataUser.length != 0) {
                    this.userChargeLoader = true;
                    this.dataUser = [];
                    this.newDataUser.forEach((element) => {
                        if (element.trajet >= 1) {
                            this.dataUser.push(element);
                        }
                    });

                    this.newDataUser = this.dataUser;
                    this.userChargeLoader = false;
                } else {
                    this.userChargeLoader = true;
                    this.dataUser.forEach((element) => {
                        if (element.trajet >= 1) {
                            this.newDataUser.push(element);
                        }
                    });
                    this.dataUser = [];
                    this.userChargeLoader = false;
                }
            } else {
                if (this.newDataUser.length != 0) {
                    this.dataUser = [];
                    this.actif(this.startValue, this.endValue);
                    this.userChargeLoader = false;
                } else {
                    console.log("non non non");

                    this.init();
                    this.userChargeLoader = false;
                }
            }
        },
    },
};

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row rowBase">
            <div class="col-lg-12 ">
                <div class="card p-4 ">

                    <div class="mb-4">
                        Gestion des services
                    </div>


                    <b-tabs pills card content-class="mt-3" justified>

                        <b-tab active class="" title=" Gestion des services">

                            <b-table striped responsive :items="serviceData" :fields="covFields">

                                <!-- Colonne pour les actions sur la distance minimum -->
                                <template #cell(distance_minimum)="row">
                                    <div @click="handleDistanceUpdateClick(row.item)">
                                        <!-- Affiche la distance minimale ou un message si non définie -->
                                        {{ row.item.distance_minimum || 'Non défini' }} <i class="fas fa-edit"></i>
                                    </div>
                                </template>

                                <!-- Colonne pour les actions -->
                                <template #cell(action)="row">
                                   

                                    <div class="flex justfy-content-center text-center">
                                       
                                        <b-form-checkbox switch v-model='row.item.active' size="lg"
                                        @change="handleServiceChange(row.item)">
                                        </b-form-checkbox>
                                    </div>
                                </template>

                                <!-- Colonne pour l'état actif ou inactif du service -->
                                <template #cell(active)="row">
                                   

                                    <div class="flex justfy-content-center text-left">
                                       
                                        <span v-if="row.item.active">
                                                Actif
                                        </span>
                                        <span v-else>
                                            Inactif

                                        </span>
                                    </div>
                                </template>

                                <!-- Colonne pour les dates d'ouverture -->
                                <template #cell(ouverture)="row">
                                   

                                   <div class="flex justfy-content-center text-left">
                                      <div @click="handleUpdateClick(row.item)">
                                        {{ row.value ? new Date(row.value).toLocaleDateString("FR-fr"):"  pas de date "  }} <i class="fas fa-pen"></i>
                                      </div> 
                                   </div>
                               </template>
                            </b-table>

                        </b-tab>
                    </b-tabs>

                </div>
            </div>
        </div>

        <!-- Modal pour modifier la distance minimale -->
       
        <b-modal size="sm" v-model="editDistanceModal" hide-footer title="Modifier la distance minimum">
            <div class="form-group">
                <label for="distance_minimum">Distance Minimum (km)</label>
                <input 
                    type="number" 
                    v-model.number="distance_minimum" 
                    class="form-control" 
                    id="distance_minimum"
                    min="0"
                    step="1"
                    required
                >
            </div>
            <div class="d-flex justify-content-end mt-3">
                <button class="btn btn-secondary mr-2" @click="editDistanceModal = false">Annuler</button>
                <button 
                    class="btn btn-primary" 
                    @click="updateDistanceMinimum()"
                    :disabled="!distance_minimum || distance_minimum < 0"
                >
                    Enregistrer
                </button>
            </div>
        </b-modal>
        <!-- Modal pour modifier la date de reprise -->
        <b-modal size="sm" v-model="dateModal" hide-footer>
            
            <div class="form-group">
                <label for="dateouverture">Date de reprise</label>
                <input type="date" v-model="dateOuverture" id="dateouverture" class="form-control">

            </div>

            <div class="d-flex">
                <a href="#" class="btn btn-primary" @click.prevent="updateDate()">Modifier</a>
            </div>

        </b-modal>
    </Layout>
</template>

<style>
.box {
    background-color: #3AAA35;
    color: white;
    border-radius: 5px;
    font-size: 1.5rem;
    padding: 20px;
    box-shadow: 5px 10px rgba(144, 238, 144, 0.417);
}

.box-tarif {
    font-weight: light;
    font-size: 1em;
}

.formule {
    font-weight: bold;
}

.detail-prix {
    font-size: 0.6em;
    font-weight: light;
}

thead {
    background: #3AAA35;
}


thead div {
    color: #fff;

}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.lineField td:nth-child(5) {
    max-width: 250px !important;
    white-space: nowrap;
    overflow-x: scroll !important;
    overflow-y: auto !important;
}

.lineField td:nth-child(2) {
    max-width: 200px !important;
    white-space: nowrap;
    overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.bg-color-custom {
    background-color: #3AAA35 !important;
    color: white;

}

.seat-bg {
    background-color: #5bcd3e;
}

.day {
    background-color: rgb(231, 225, 225);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    /* Ajustez la taille selon vos besoins */
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
}

.day:hover {

    color: #fff;
    background-color: rgb(104, 87, 131) !important;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.containerDot {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    font-size: 2rem;
    color: hsl(0, 0%, 12%);
    width: 250px;
    height: 40px;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pac-container {
    z-index: 10000 !important;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

.nav-tabs .nav-link {
    background: #dc143c;
    transition: all .5s;
}

.nav-tabs .nav-link a {
    color: #fff;
}

.text-red {
    color: #dc143c;
}

.nav-tabs .nav-link a {
    color: #fff !important;
}

.text-green {
    color: #3AAA35;
}

.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: blue;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    margin: auto;
    background-color: #d5d5d5;

    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
        10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
    background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
    display: block;

}

input[type="checkbox"]:after {
    font-weight: 600;
    font-family: FontAwesome;
    content: "\f00c";
    width: inherit;
    height: inherit;

    font-size: 20px;
    color: white;
    display: none;
}


.seat-container {
    border: 2px solid black;
    width: 35px;
    height: 35px;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
    /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
    display: flex;
    flex-direction: row;
}
</style>
